module.exports = {
  appDefault: {
    facebook_bundle: 'fb326410019008941',
    codepush_api_token: '3f4ea6d8246359fdcbd2ddd9c0c463040237713e',
    web_client_id: '939456025008-kq6c8v018mojjh6c2p5a27ksmi7jaqim',
    metadata_ios_description:
      'Com o aplicativo do Clube Mercafacil você tem acesso aos descontos exclusivos do Clube onde estiver. E ainda pode adicionar os seus itens preferidos a uma lista de compras ou criar uma lista de itens favoritos para acompanhar se entram em oferta.\n\nTudo o que você precisa para comprar melhor\nDescubra as melhores promoções, separadas com exclusividade para você;\nCrie suas listas de compras de um jeito rápido e fácil;\nAdicione seus produtos preferidos a uma lista de favoritos;\nVeja no mapa o endereço da loja mais próxima, além de informações de contato, horário de funcionamento e atendimento ao consumidor;\nTenha acesso aos canais digitais do seu supermercado.\n\n\nBaixe agora e tenha uma nova experiência de compra!',
    metadata_ios_short_description: 'Interaja com as promoções, novidades e informações do Clube Mercafacil.',
    metadata_ios_keywords: 'Supermercado,Ofertas,Clube,Desconto,Mercafacil',
    metadata_ios_marketing_url: 'https://www.mercafacil.com/',
    metadata_ios_name: 'Clube Mercafacil',
    metadata_ios_privacy_url: 'https://regulamentos.mercafacil.com/mercafacil_politica_privacidade.html',
    metadata_ios_promotional_text:
      'Interaja com as promoções, novidades e informações do Clube Mercafacil.\n\nTudo para transformar sua experiência de compra.',
    metadata_ios_release_notes: 'Refizemos nosso login e cadastro e alguns ajustes no visual das ofertas para melhorar a experiencia dos usuários.',
    metadata_ios_support_url: 'https://www.mercafacil.com/',
    metadata_android_full_description:
      'Com o aplicativo do Clube Mercafacil você tem acesso aos descontos exclusivos do Clube onde estiver. E ainda pode adicionar os seus itens preferidos a uma lista de compras ou criar uma lista de itens favoritos para acompanhar se entram em oferta.\n\nTudo o que você precisa para comprar melhor\nDescubra as melhores promoções, separadas com exclusividade para você;\nCrie suas listas de compras de um jeito rápido e fácil;\nAdicione seus produtos preferidos a uma lista de favoritos;\nVeja no mapa o endereço da loja mais próxima, além de informações de contato, horário de funcionamento e atendimento ao consumidor;\nTenha acesso aos canais digitais do seu supermercado.\n\n\nBaixe agora e tenha uma nova experiência de compra!',
    metadata_android_short_description: 'Interaja com as promoções, novidades e informações do Clube Mercafacil.',
    metadata_android_title: 'Clube Mercafacil',
    old_colors: {
      main: '#FFFFFF',
      main_text: '#000000',
      secondary: '#FFFFFF',
      secondary_text: '#000000',
      nav: '#FFFFFF',
      nav_text: '#000000',
      splash: '#FFFFFF'
    },
    colors: {
      primary: '#FFFFFF',
      primary_text: '#000000',
      primary_light: '#FFFFFF',
      primary_text_light: '#000000',
      primary_dark: '#FFFFFF',
      primary_text_dark: '#000000',
      secondary: '#FFFFFF',
      secondary_text: '#000000',
      secondary_light: '#FFFFFF',
      secondary_text_light: '#000000',
      secondary_dark: '#FFFFFF',
      secondary_text_dark: '#000000'
    }
  },
  appClub: {
    facebook_bundle: 'fb326410019008941',
    codepush_api_token: '3f4ea6d8246359fdcbd2ddd9c0c463040237713e',
    web_client_id: '939456025008-kq6c8v018mojjh6c2p5a27ksmi7jaqim',
    metadata_ios_description:
      'Com o aplicativo Clube Mercafacil você tem acesso aos descontos exclusivos do Clube onde estiver. Através do aplicativo,  você fica por dentro das promoções e campanhas que estão rolando.\nVeja todas as ofertas e descontos exclusivos feitos para você.\nTudo o que você precisa para comprar melhor!\n\nSeu SUPERMERCADO agora está muito mais próximo de você!\n\nBaixe agora e tenha uma nova experiência de compra!',
    metadata_ios_short_description: 'Interaja com as promoções, novidades e informações do Clube Mercafacil.',
    metadata_ios_keywords: 'Supermercado,Ofertas,Clube,Desconto,Mercafacil',
    metadata_ios_marketing_url: 'https://www.mercafacil.com/',
    metadata_ios_name: 'Clube Mercafacil',
    metadata_ios_privacy_url: 'https://regulamentos.mercafacil.com/mercafacil_politica_privacidade.html',
    metadata_ios_promotional_text:
      'Interaja com as promoções, novidades e informações do Clube Mercafacil.\n\nTudo para transformar sua experiência de compra.',
    metadata_ios_release_notes: 'Refizemos nosso login e cadastro e alguns ajustes no visual das ofertas para melhorar a experiencia dos usuários.',
    metadata_ios_support_url: 'https://www.mercafacil.com/',
    metadata_android_full_description:
      'Com o aplicativo Clube Mercafacil você tem acesso aos descontos exclusivos do Clube onde estiver. Através do aplicativo,  você fica por dentro das promoções e campanhas que estão rolando.\nVeja todas as ofertas e descontos exclusivos feitos para você.\nTudo o que você precisa para comprar melhor!\n\nSeu SUPERMERCADO agora está muito mais próximo de você!\n\nBaixe agora e tenha uma nova experiência de compra!',
    metadata_android_short_description: 'Interaja com as promoções, novidades e informações do Clube Mercafacil.',
    metadata_android_title: 'Clube Mercafacil',
    colors_main: '#FFFFFF',
    colors_main_text: '#000000',
    colors_nav: '#FFFFFF',
    colors_nav_text: '#000000',
    colors_secondary: '#FFFFFF',
    colors_secondary_text: '#000000',
    colors_splash: '#FFFFFF'
  },
  appEcommerce: {
    facebook_bundle: 'fb326410019008941',
    codepush_api_token: '3f4ea6d8246359fdcbd2ddd9c0c463040237713e',
    web_client_id: '939456025008-kq6c8v018mojjh6c2p5a27ksmi7jaqim',
    metadata_ios_description:
      'O aplicativo Clube Mercafacil oferece um amplo conjunto de funcionalidades com um visual moderno e intuitivo.\n\nAgora o cliente do Clube Mercafacil pode fazer suas compras pelo celular em qualquer horário e agendar a entrega das mercadorias.\n\nDeixe de carregar o peso das compras, receba os produtos no conforto da sua casa. Economize tempo, despesas com transporte, stress no trânsito e nas filas.\n\nO App Clube Mercafacil está integrado com o site https://www.mercafacil.com/ \n\n Para utilizar é muito simples:\n1 - Selecione os produtos: navegue pelas categorias e escolha os seus itens.\n2 - Confira sua compra no carrinho: veja os itens que você incluiu.\n3 - Caso seja sua primeira visita, precisaremos de algumas informações para conseguir enviar sua compra.\n4 - Selecione o horário de entrega ou retirada.\n5 - Escolha a forma de pagamento e finalize a sua compra.',
    metadata_ios_short_description: 'Compre online através do aplicativo.',
    metadata_ios_keywords: 'Supermercado,Ofertas,Clube,Desconto,Mercafacil',
    metadata_ios_marketing_url: 'https://www.mercafacil.com/',
    metadata_ios_name: 'Clube Mercafacil',
    metadata_ios_privacy_url: 'https://regulamentos.mercafacil.com/mercafacil_politica_privacidade.html',
    metadata_ios_promotional_text:
      'Interaja com as promoções, novidades e informações do Clube Mercafacil.\n\nTudo para transformar sua experiência de compra.',
    metadata_ios_release_notes: 'Refizemos nosso login e cadastro e alguns ajustes no visual das ofertas para melhorar a experiencia dos usuários.',
    metadata_ios_support_url: 'https://www.mercafacil.com/',
    metadata_android_full_description:
      'O aplicativo Clube Mercafacil oferece um amplo conjunto de funcionalidades com um visual moderno e intuitivo.\n\nAgora o cliente do Clube Mercafacil pode fazer suas compras pelo celular em qualquer horário e agendar a entrega das mercadorias.\n\nDeixe de carregar o peso das compras, receba os produtos no conforto da sua casa. Economize tempo, despesas com transporte, stress no trânsito e nas filas.\n\nO App Clube Mercafacil está integrado com o site https://www.mercafacil.com/ \n\n Para utilizar é muito simples:\n1 - Selecione os produtos: navegue pelas categorias e escolha os seus itens.\n2 - Confira sua compra no carrinho: veja os itens que você incluiu.\n3 - Caso seja sua primeira visita, precisaremos de algumas informações para conseguir enviar sua compra.\n4 - Selecione o horário de entrega ou retirada.\n5 - Escolha a forma de pagamento e finalize a sua compra.',
    metadata_android_short_description: 'Compre online através do aplicativo.',
    metadata_android_title: 'Clube Mercafacil',
    colors_main: '#FFFFFF',
    colors_main_text: '#000000',
    colors_nav: '#FFFFFF',
    colors_nav_text: '#000000',
    colors_secondary: '#FFFFFF',
    colors_secondary_text: '#000000',
    colors_splash: '#FFFFFF'
  },
  appPhygital: {
    facebook_bundle: 'fb326410019008941',
    codepush_api_token: '3f4ea6d8246359fdcbd2ddd9c0c463040237713e',
    web_client_id: '939456025008-kq6c8v018mojjh6c2p5a27ksmi7jaqim',
    metadata_ios_description:
      'Com o aplicativo Clube Mercafacil você tem acesso aos descontos exclusivos do Clube. Além disso, você pode fazer suas compras pelo celular em qualquer horário e agendar a entrega das mercadorias.\n\nAtravés do aplicativo, você fica por dentro das promoções e campanhas que estão rolando, com possibilidade de comprar online, podendo receber ou retirar no local e horário agendados.\n\nDeixe de carregar o peso das compras, receba os produtos no conforto da sua casa. Economize tempo, despesas com transporte, stress no trânsito e nas filas.\n\nTudo o que você precisa para comprar melhor!\n\nSeu SUPERMERCADO agora está muito mais próximo de você!\n\nBaixe agora e tenha uma nova experiência de compra! ',
    metadata_ios_short_description: 'Interaja com as promoções, e compre online através do aplicativo.',
    metadata_ios_keywords: 'Supermercado,Ofertas,Clube,Desconto,Mercafacil',
    metadata_ios_marketing_url: 'https://www.mercafacil.com/',
    metadata_ios_name: 'Clube Mercafacil',
    metadata_ios_privacy_url: 'https://regulamentos.mercafacil.com/mercafacil_politica_privacidade.html',
    metadata_ios_promotional_text:
      'Interaja com as promoções, novidades e informações do Clube Mercafacil.\n\nTudo para transformar sua experiência de compra.',
    metadata_ios_release_notes: 'Refizemos nosso login e cadastro e alguns ajustes no visual das ofertas para melhorar a experiencia dos usuários.',
    metadata_ios_support_url: 'https://www.mercafacil.com/',
    metadata_android_full_description:
      'Com o aplicativo Clube Mercafacil você tem acesso aos descontos exclusivos do Clube. Além disso, você pode fazer suas compras pelo celular em qualquer horário e agendar a entrega das mercadorias.\n\nAtravés do aplicativo, você fica por dentro das promoções e campanhas que estão rolando, com possibilidade de comprar online, podendo receber ou retirar no local e horário agendados.\n\nDeixe de carregar o peso das compras, receba os produtos no conforto da sua casa. Economize tempo, despesas com transporte, stress no trânsito e nas filas.\n\nTudo o que você precisa para comprar melhor!\n\nSeu SUPERMERCADO agora está muito mais próximo de você!\n\nBaixe agora e tenha uma nova experiência de compra! ',
    metadata_android_short_description: 'Interaja com as promoções, e compre online através do aplicativo.',
    metadata_android_title: 'Clube Mercafacil',
    colors_main: '#FFFFFF',
    colors_main_text: '#000000',
    colors_nav: '#FFFFFF',
    colors_nav_text: '#000000',
    colors_secondary: '#FFFFFF',
    colors_secondary_text: '#000000',
    colors_splash: '#FFFFFF'
  }
}
